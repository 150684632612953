import React, { Component } from "react"
import { Helmet } from "react-helmet"

import HeroImage from '../components/1.4-safety-side-effects/HeroImage'
import Main from '../components/1.4-safety-side-effects/Main'
import Header from "../components/header/Header"
import ISI from '../components/isi/ISI'
import Footer from '../components/footer/Footer'
import Modal from '../components/modal/Modal'
import HomeModal from '../components/modal/HomeModal'
import SEO from "../components/seo"

import Loader from "../components/loader"



export default class Safety extends Component {

  constructor(props) {
    super(props)
    this.state = {
       stickyNav: false,
       isMobile: false,
       modal:  null
    }
  }

  componentDidMount() {
    let bool = window.matchMedia("only screen and (max-width: 1023px)").matches;
    this.setState({ isMobile: bool })
  }

  handleClick(e) {
    // console.log('clicked');
  }

  goModal = (evt) => {
    // let str = evt.target.dataset.modal;
    this.setState(() => ({ modal: evt }))
  }

  modalClosed = () => {
    this.setState(() => ({ modal: null }))
  }


  render() {

    return (
      <>
      <Loader>
      <SEO
        title="VASCEPA Safety Profile and Side Effects"
        keywords={[""]}
        description="VASCEPA has a well-established safety profile. Learn about safety and side effects of VASCEPA."/>
        <HomeModal/>

        <Modal modal={this.state.modal} modalClosed={this.modalClosed}/>
        <Header isMobile={this.state.isMobile} hideNav={this.state.hideNav} goModal={this.goModal}/>
        <div className="grad"></div>
        <HeroImage onClick={this.handleClick} bckgrdImage={'header1-4'}/>
        <div className="wrapper">
          <Main isMobile={this.state.isMobile} goModal={this.goModal}/>
        </div>
        <ISI scrollPos={this.state.unstick} disclamer="Visual representations are for illustrative purposes only. The capsule shown is not an identical representation of the product."/>
          <div className="ref-outer">
        {/* <div className="footnotes-container">
          <div className="footnotes">
            <p className="cv-footnote hang">*Data on file.</p>
          </div>
        </div> */}
          </div>
        <Footer />
        </Loader>
      </>

    )
  }
}
