import React from "react"
import './styles.css'


export default (props) => (
  <main className="center-grid mobile-m-b-20">
    <div className="safety-grid">
      <article className='safety'>
          <ul className="content-list">
            <li className="primary-list-item">VASCEPA was associated with an increased risk (3% vs 2%) of atrial fibrillation or atrial flutter requiring hospitalization in a double-blind, placebo-controlled trial. The incidence of atrial fibrillation was greater in patients with a previous history of atrial fibrillation or atrial flutter
            </li>
          </ul>
      </article>

      <article className='safety border-left'>
          <ul className="content-list">
            <li className="primary-list-item">VASCEPA was associated with an increased risk (12% vs 10%) of bleeding in a double-blind, placebo-controlled trial. The incidence of bleeding was greater in patients receiving concomitant antithrombotic medications, such as aspirin, clopidogrel or warfarin
            </li>
          </ul>
      </article>

      <article className='safety border-left'>
          <ul className="content-list">
            <li className="primary-list-item">Common adverse reactions in the cardiovascular outcomes trial (incidence ≥3% and ≥1% more frequent than placebo): musculoskeletal pain (4% vs 3%), peripheral edema (7% vs 5%), constipation (5% vs 4%), gout (4% vs 3%) and atrial fibrillation (5% vs 4%)
            </li>
          </ul>
      </article>
    </div>
    <div className="outcomes-callout safety">
      <div className="cta-container m-center fit-content">
        <h3 className="cta-copy">Start patients on VASCEPA</h3>
        <a href="/getting-patients-started/" className="cta-btn">Learn how</a>
      </div>
    </div>

  </main>
)
